<template>
  <v-sheet elevation="1">
    <v-toolbar elevation="0" dense>
      <v-toolbar-title>Comments</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text small icon @click="getComments(true)">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-refresh</v-icon>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-btn>
      <AddComment :cpar_header_id="cpar_header_id" @callback="getComments(true)"></AddComment>
    </v-toolbar>
    <v-divider></v-divider>
    <div class="mb-2 pa-2 d-block text-center" v-if="(loading == true)">
      <v-progress-circular color="primary" indeterminate class="mx-auto"></v-progress-circular>
    </div>
    <div class="mb-2 pa-2" v-if="(comments.length == 0) && (loading == false)">
      <v-alert type="info">Comment not found!</v-alert>
    </div>
    <div class="mb-2 pa-2" v-if="comments.length > 0">
      <div v-for="(item, i) in comments_limit" :key="i">
        <comment-card :data="item"></comment-card>
      </div>
      <v-dialog v-model="dialog" width="600" scrollable v-if="comments.length > limit">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="red lighten-2" text block small v-bind="attrs" v-on="on">
            Show All
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            <span>All Comment</span>
            <v-spacer></v-spacer>
            <v-btn text small icon @click="getComments(true)">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-refresh</v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>
            </v-btn>
            <AddComment :cpar_header_id="cpar_header_id" @callback="getComments(true)"></AddComment>
            <v-btn text small icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="py-2">
            <!-- <v-card max-height="1000" class="overflow-auto pa-0" elevation="0" flat>
              <v-card-text class="ps-0"> -->
                <div v-for="(item, i) in comments" :key="i">
                  <CommentCard :data="item"></CommentCard>
                </div>
              <!-- </v-card-text>
            </v-card> -->
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-sheet>
</template>

<script>
import AddComment from './AddComment.vue';
import CommentCard from './CommentCard.vue';
export default {
  props: {
    cpar_header_id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    AddComment,
    CommentCard,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      comments: [],
      limit: 5
    }
  },
  computed: {
    comments_limit() {
      var cl = [];
      for (var i = 0; i < this.limit; i++) {
        cl.push(this.comments[i]);
      }
      return cl;
    }
  },
  methods: {
    async getComments(refresh = false) {

      this.loading = true

      this.comments = {}

      if (refresh == true) this.AxiosStorageRemove("GET", "cpar/comment/list");

      // this.showLoadingOverlay(true);

      var config = {
        params: {
          cpar_header_id: this.cpar_header_id,
        },
        cacheConfig: false,
      };
      await this.$axios
        .get("cpar/comment/list", config)
        .then((res) => {
          this.comments = res.data.data.comments;

          // this.showLoadingOverlay(false);

          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          // this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },

  mounted() {
    this.getComments(true)
  }
}
</script>