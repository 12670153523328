<template>
  <v-card elevation="0" outlined shaped class="mb-2"  color="grey lighten-4" v-if="(typeof data != 'undefined')">
    <v-card-title class="d-flex py-1" style="line-height: normal;">
      <v-avatar>
        <v-img :src="resourceUrl(data.user_img)"></v-img>
      </v-avatar>
      <div class="px-2">
        <h6 style="font-size: 14px;">{{ data.username }}</h6>
        <small class="d-block text--disabled" style="font-size: 12px;">{{ data.employee_position }}</small>
        <small class="d-block text--disabled" style="font-size: 12px;">{{ data.comment_datetime }}</small>
      </div>
    </v-card-title>
    <v-card-text class="py-0" style="max-height: 100px;overflow-y: hidden;">{{ data.comment }}</v-card-text>
    <v-card-actions class="pt-0">
      <v-spacer></v-spacer>
      <CommentDetail :data="data"></CommentDetail>
    </v-card-actions>
  </v-card>
</template>

<script>
import CommentDetail from './CommentDetail.vue';
export default {
  components: { CommentDetail },
  props: ["data"],

}
</script>